<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col align="center" justify="center">
        <v-card max-width="500">
          <v-card-title class="card-title">
            <h3>
              {{ $t("notFound.title") }}
            </h3>
          </v-card-title>

          <v-card-text>
            <v-container class="pb-0">
              <v-row>
                <v-col>
                  <span class="secondary--text">{{ $t("notFound.doesNotExist") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <span>{{ $t("notFound.location") }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-8">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>{{ $t("notFound.appOwner") }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>